/* global gettext, interpolate */

import { createContext, render } from "preact"
import { useContext, useMemo, useState, useRef, useEffect } from "preact/hooks"
import { Masonry } from "../lib/masonry"
import { qs, qsa } from "../utils/dom"
import { useBreakpoint } from "../utils/breakpoints"

const TestimonialContext = createContext()
const useTestimonialContext = () => useContext(TestimonialContext)

const DEFAULTS = {
  search: true,
  paginateBy: 6,
  listBase: "",
  support: "%(name)s",
}

export const initTestimonials = () => {
  let data
  for (let testimonialsEl of qsa("[data-testimonials]")) {
    if ((data = qs("script", testimonialsEl))) {
      render(
        <TestimonialContext.Provider
          value={{ ...DEFAULTS, ...testimonialsEl.dataset }}
        >
          <TestimonialsApp data={JSON.parse(data.textContent || {})} />
        </TestimonialContext.Provider>,
        testimonialsEl,
      )
    }
  }
}

// Thanks, https://stackoverflow.com/a/37511463
const unaccent = (s) => s.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

const filterTestimonials = (data, filterWord) => {
  const term = unaccent(filterWord).toLowerCase()
  return data.filter((obj) => obj.searchString.includes(term))
}

const TestimonialsApp = ({ data }) => {
  const testimonials = useMemo(
    () =>
      data.map((t) => ({ ...t, searchString: unaccent(t.name).toLowerCase() })),
    [data],
  )
  //console.log({ testimonials })
  const { paginateBy, search } = useTestimonialContext()
  const [slices, setSlices] = useState(1)
  const [filterWord, setFilterWord] = useState("")

  const filteredTestimonials = filterTestimonials(testimonials, filterWord)
  const shownTestimonials = filteredTestimonials.slice(0, slices * paginateBy)

  const showNext = slices * paginateBy < filteredTestimonials.length
  // const showPrevious = (slices - 1) * paginateBy > 0

  const columnsM = useBreakpoint("md") && 2
  const columnsL = useBreakpoint("lg") && 3

  return (
    <>
      {search ? (
        <div class="form plugin plugin--testimonials-search">
          <span class="testimonials__search">
            <input
              class="input__search"
              type="text"
              value={filterWord}
              onInput={(e) => setFilterWord(e.target.value)}
            />
            <span>{gettext("suchen")}</span>
          </span>
        </div>
      ) : null}
      <Masonry
        columns={columnsL || columnsM || 1}
        styleClass="testimonials-grid"
        columnStyleClass="testimonials-grid__column"
      >
        {shownTestimonials.length < 1 && (
          <div class="">{gettext("Keine Botschaften gefunden.")}</div>
        )}
        {shownTestimonials.map((testimonial) => (
          <TestimonialBox key={testimonial.id} data={testimonial} />
        ))}
      </Masonry>
      {showNext ? (
        <InfiniteScroll
          increment={() => {
            setSlices(slices + 1)
          }}
        />
      ) : null}
    </>
  )
}

const TestimonialBox = ({ data }) => {
  const { listBase, support } = useTestimonialContext()
  const sharingText = `${encodeURI(interpolate(support, data, true))} \n`
  const sharingURL = `${listBase}${data.id}/`

  return (
    <div class="testimonials-grid__item">
      <img
        class="testimonials-grid__image"
        src={data.portrait}
        alt={`${gettext("Portrait of")} ${data.name}`}
      />
      {data.statement ? (
        <div class="testimonials-grid__statement">«{data.statement}»</div>
      ) : null}
      {data.statement ? <div class="testimonials-grid__separator" /> : null}
      <div class="testimonials-grid__name">{data.name}</div>
      <div class="testimonials-grid__profession">{data.profession}</div>
      <div class="testimonials-grid__sharing">
        <ShareLink
          title={gettext("Share on Facebook")}
          url={`https://www.facebook.com/sharer.php?u=${sharingURL}`}
        >
          <svg class="icon">
            <use xlinkHref="#icon-facebook" />
          </svg>
        </ShareLink>
        <ShareLink
          title={gettext("Share on Twitter")}
          url={`https://twitter.com/share?url=${sharingURL}`}
        >
          <svg class="icon">
            <use xlinkHref="#icon-twitter" />
          </svg>
        </ShareLink>
        <ShareLink
          title={gettext("Share on Whatsapp")}
          url={`https://api.whatsapp.com/send?phone=&text=${sharingText}${sharingURL}`}
        >
          <svg class="icon">
            <use xlinkHref="#icon-whatsapp" />
          </svg>
        </ShareLink>
        <ShareLink
          title={gettext("Share on Telegram")}
          url={`https://t.me/share/url?url=${sharingURL}&text=${sharingText}`}
        >
          <svg class="icon">
            <use xlinkHref="#icon-telegram" />
          </svg>
        </ShareLink>
        <DownloadLink
          src={data.sharing_image_square}
          title={gettext("Share on Instagram")}
        >
          <svg class="icon">
            <use xlinkHref="#icon-instagram" />
          </svg>
        </DownloadLink>
        <DownloadLink
          src={data.sharing_image_square}
          title={gettext("Download testimonial")}
        >
          <svg class="icon">
            <use xlinkHref="#icon-download" />
          </svg>
        </DownloadLink>
      </div>
    </div>
  )
}

const ShareLink = ({ title, url, children }) => {
  return (
    <a href={url} title={title} target="_blank" rel="noopener noreferrer">
      <span class="sr-only">{title}</span>
      {children}
    </a>
  )
}

const DownloadLink = ({ title, src, children }) => {
  return (
    <a href={src} title={title} download="sharing_image.png">
      <span class="sr-only">{title}</span>
      {children}
    </a>
  )
}

const InfiniteScroll = ({ increment }) => {
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      const { isIntersecting, target } = entry
      if (isIntersecting) {
        observer.unobserve(target)
        increment()
      }
    })
  })

  const infiniteRef = useRef()

  useEffect(() => {
    observer.observe(infiniteRef.current)
  })

  return <div class="infinite-scroll" ref={infiniteRef} />
}
