export function initToggleNav() {
  const body = document.body
  const toggleOpen = document.querySelector("[data-menu-toggle-open]")
  const toggleClose = document.querySelector("[data-menu-toggle-close]")
  const mobileMenu = document.querySelector(".mobile-menu")

  toggleOpen.addEventListener("click", openMenu)
  toggleClose.addEventListener("click", closeMenu)

  function openMenu() {
    mobileMenu.setAttribute("aria-expanded", "true")
    if (!body.classList.contains("menu-is-open"))
      body.classList.add("menu-is-open")
  }

  function closeMenu() {
    mobileMenu.setAttribute("aria-expanded", "false")
    body.classList.remove("menu-is-open")
  }

  body.addEventListener("keydown", (e) => {
    if (e.key === "Escape" && body.classList.contains("menu-is-open")) {
      closeMenu()
    }
  })
}

export function initScrolledNav() {
  const handleScroll = () => {
    document.body.classList.toggle("scrolled", window.scrollY > 16)
  }
  handleScroll()
  window.addEventListener("scroll", handleScroll, { passive: true })
}
