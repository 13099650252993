/* global gettext */
import { render } from "preact"
import { useState } from "preact/hooks"
import { qs } from "/js/utils/dom"
import { Endpoint } from "/js/utils/endpoint"

export const initNewsletterForm = () => {
  const newsletterFormElement = qs("#newsletter-form")
  const newsletterTexts = qs("#newsletter-texts")

  if (newsletterFormElement && newsletterTexts)
    render(
      <NewsletterForm
        endpoint={new Endpoint(newsletterFormElement.dataset.endpoint)}
        texts={JSON.parse(newsletterTexts.textContent)}
      />,
      newsletterFormElement,
    )
}

const NewsletterForm = ({ endpoint, texts }) => {
  const [status, setStatus] = useState("")
  const [statusText, setStatusText] = useState("")

  const onsubmit = async (e) => {
    e.preventDefault()
    setStatus("loading")

    const formData = new FormData(e.target)
    let response = await endpoint.postData(formData)

    setStatus(response.status)
    setStatusText(response.statusText)
  }

  return (
    <form onSubmit={onsubmit} class="form form--wide-fields">
      {status === "error" && (
        <div class="message">
          {gettext("Fehler beim Absenden des Formulars.")}
        </div>
      )}
      {status === "" ? (
        <>
          <div class="field-50-50">
            <SelectInput
              name={"salutation"}
              options={[
                [gettext("Neutrale Anrede"), gettext("Neutrale Anrede")],
                [gettext("Frau"), gettext("Frau")],
                [gettext("Herr"), gettext("Herr")],
              ]}
            />
          </div>
          <div class="field-50-50">
            <TextInput
              label={gettext("Vorname")}
              name={"given_name"}
              type={"text"}
              maxLength={"50"}
              required
            />
            <TextInput
              label={gettext("Nachname")}
              name={"family_name"}
              type={"text"}
              maxLength={"50"}
              required
            />
          </div>
          <div class="field-50-50">
            <TextInput
              label={gettext("Postleitzahl")}
              name={"postal_code"}
              type={"text"}
              maxLength={"20"}
              required
            />
            <TextInput
              label={gettext("E-Mail")}
              name={"email"}
              type={"email"}
              maxLength={"50"}
              required
            />
          </div>
          {/* <CheckboxInput
            label={gettext("additonal_newsletter_text")}
            name={"additional_newsletter"}
          /> */}
          <CheckboxInput
            label={texts["terms_and_conditions"]}
            name={"terms_and_conditions"}
            required
          />
          <div class="formgroup formgroup--submit">
            <input type="submit" class="button" value={gettext("Anmelden")} />
          </div>
        </>
      ) : (
        <div class="">{statusText}</div>
      )}
    </form>
  )
}

const TextInput = ({ label, name, type, maxLength, required }) => {
  const id = `id_${name}`
  return (
    <div class="field widget--textinput required">
      <label for={id} class={required ? "required" : null}>
        {label}
      </label>
      <input
        class={required ? "required" : null}
        id={id}
        name={name}
        type={type}
        maxLength={maxLength}
        value=""
        required={required}
      />
    </div>
  )
}

const SelectInput = ({ name, required, options }) => {
  const id = `id_${name}`
  return (
    <div class="field widget--selectinput required">
      {" "}
      <select id={id} name={name} required={required}>
        {options.map((option) => {
          return (
            <option key={option[0]} value={option[0]}>
              {option[1]}
            </option>
          )
        })}
      </select>
    </div>
  )
}

const CheckboxInput = ({ label, name, required }) => {
  const id = `id_${name}`
  return (
    <div class="field widget--checkboxinput required">
      <div>
        <input type="checkbox" name={name} required={required} id={id} />{" "}
        <label class={required ? "required" : null} for={id}>
          {label}
        </label>
      </div>
    </div>
  )
}
