import { setDetailsHeights } from "/js/utils/details"
import { qsa } from "/js/utils/dom"

export const initFAQ = () => {
  const faqs = qsa(".faq__details")
  setDetailsHeights(faqs)

  // function clickHandler(faq) {
  //   faq.addEventListener("click", (e) => {
  //     faqs.map((otherFaq) => {
  //       if (
  //         e.target.closest("details") != otherFaq &&
  //         otherFaq.hasAttribute("open")
  //       ) {
  //         otherFaq.removeAttribute("open")
  //       }
  //     })
  //   })
  // }

  // faqs.forEach((faq) => {
  //   clickHandler(faq)
  // })
}
